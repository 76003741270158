$('#PhoneNumber').inputmask("+421999 999 999", {
    clearMaskOnLostFocus: false,
});

$('#DateOfBirth').inputmask("9[9].9[9].9999", {
    clearMaskOnLostFocus: false,
    placeholder: 'dd.mm.yyyy',
});

//set datepicker
var yearRange = ((new Date()).getFullYear() - 118) + ':' + ((new Date()).getFullYear() - 17);
$.datepicker.setDefaults($.datepicker.regional['sk']);
$('[name="DateOfBirth"]').datepicker({
    changeMonth: true,
    changeYear: true,
    yearRange: yearRange,
    defaultDate: new Date((new Date()).getFullYear() - 17, 0, 1)
})

// file upload logic --------------------------------------------------------------------
function getUploadedFileCount() {
    return $('.file-input').filter(function () {
        return (!!this.value) || $(this).hasClass('required');
    }).length;
}

//recalculates indexes of uploaded files (0-n) so server can handle it as list
function recalculateIndexes(el) {
    var $parentBlock = el.attr("data-attachmentblock") ? el : el.closest('[data-attachmentblock]');
    var fileInputEls = $parentBlock.find('.file-input');
    var attIDElements = $parentBlock.find('[data-attachmentid]');

    fileInputEls.each(function (index, el) {
        var fileel = $(fileInputEls[index]);
        var attODel = $(attIDElements[index]);

        fileel
            .attr("name", fileel.attr("name").replace(/\[(\d)\]\./gi, function (m, n) {
                return "[" + (index) + "]."
            }))
            .attr("id", fileel.attr("id").replace(/__\d__/, "__" + (index) + "__"))

        attODel
            .attr("data-index", index + 1)
            .attr("name", attODel.attr("name").replace(/\[(\d)\]\./gi, function (m, n) {
                return "[" + (index) + "]."
            }))
            .attr("id", attODel.attr("id").replace(/__\d__/, "__" + (index) + "__"))
    });
}

//if user selects a file
$(document).change(function (e) {
    var $el = $(e.target);
    if ($el.is('input[type=file]')) {

        if ($el[0].files.length == 0) return;

        var name = $el[0].files[0].name;
        var size = $el[0].files[0].size / 1024 / 1024;

        //check size, if not valid alert + field reset + return
        if (size > maxSizeMb) {
            alert(maxFileSizeErrorMessage)
            $el.val('')
            return false
        }

        //check extension, if not valid alert + field reset + return
        var extReg = new RegExp('(\\' + allowedExtensions.split(',').join('|\\') + ')$', 'i')
        if (!extReg.exec(name)) {
            alert(acceptFilesErrorMessage)
            $el.val('')
            return false
        }

        //get closest parent row and clone it
        var clone = $el.closest('[data-fileblock]').clone()
        var fileInputEl = clone.find('.file-input');

        //set clone's file to nothing
        fileInputEl.val('')

        //append to DOM 
        $el.closest('.attachment-block').append(clone)

        //recalculate indexes
        recalculateIndexes(clone);

        //hide choose file button of selected file
        $el.closest('[data-fileblock="true"]').find('.file-upload').hide()

        //append name with cross
        name += ' <a href="javascript:void(0)" class="remove-file" >\u2716</a>'
        $el.closest('[data-fileblock="true"]').find('.file-name').show().append(name)

        //ak je to neopakovatelny required blok, odstran z klonu validaciu na required
        if (!$el.hasClass('repeated')) {
            clone.find('.file-input').removeClass('required')
            clone.hide()
        }

        //pre kazdy blok ak je dosiahnuty max a nejedna sa o required blok, schovaj tlacidla
        if (getUploadedFileCount() >= maxAttachments) {
            $('.attachment-block:not([data-required]) > [data-fileblock="true"]:last-child').hide()

        }
    }
})

$(document).click(function (e) {
    var $el = $(e.target);
    if ($el.hasClass('remove-file')) {
        var $parent = $el.closest('[data-attachmentblock]');

        //remove clicked block
        $el.closest('[data-fileblock]').remove()

        //pre required bloky
        $('.attachment-block[data-required]').each(function () {
            var atb = $(this);

            //pocet uploadnutych suborov v ramci bloku (alebo required)
            var fileLen = atb.find('.file-input')
                .filter(function () {
                    return !!this.value || $(this).hasClass('required');
                }).length

            //ak je to blok opakovatelnych priloh
            if (atb.attr('data-repeated')) {
                atb.find('[data-fileblock="true"]:last-child').show()
            } else {
                //ak je to blok NEopakovatelnych priloh
                if (fileLen === 0) {
                    //nie je uploadnuty ziaden subor
                    atb.find('[data-fileblock="true"]:last-child').show()
                }
            }

            //na prvy file input required bloku pridat class required
            atb.find('.file-input').first().addClass('required')
        })

        //pre not required bloky
        $('.attachment-block:not([data-required])').each(function () {

            if (!(getUploadedFileCount() >= maxAttachments)) {
                //zobraz tlacidlo vyberu len ak nie je naplneny max pocet priloh
                var atb = $(this);
                atb.find('[data-fileblock="true"]:last-child').show()
            }
        })

        recalculateIndexes($parent);
    }
})


//fix default range validator for checkboxes --------------------------------------------
var defaultRangeValidator = $.validator.methods.range;
$.validator.methods.range = function (value, element, param) {
    if (element.type === 'checkbox') {
        // if it's a checkbox return true if it is checked
        return element.checked;
    } else {
        // otherwise run the default validation function
        return defaultRangeValidator.call(this, value, element, param);
    }
}


$(document).ready(function () {

    $.extend($.validator.messages, {
        accept: window.acceptFilesErrorMessage || '',
        required: window.requiredFieldErrorMessage || ''
    });

    manageContractNumberVisibility();

})


//conditional requirement of date of birth -------------------------------------------
$.validator.addMethod("digiformrequireddateofbirth", function (val, el) {
    return !(
        $("input:radio[name='IsClient']:checked").val() == "Y" &&
        $("input[name='FormSetting.ShowDateOfBirth']").val() == "Y" &&
        $("input[name='IsFO']:checked").val() == "Y" &&
        val.length == 0)
})

$.validator.unobtrusive.adapters.add("digiformrequireddateofbirth", [], function (options) {
    options.rules["digiformrequireddateofbirth"] = options.params;
    options.messages["digiformrequireddateofbirth"] = options.message;
});

//conditional requirement of contract number ----------------------------------------
$.validator.addMethod("digiformrequiredcontractnumber", function (val, el) {
    return !(
        $("input:radio[name='IsClient']:checked").val() == "Y" &&
        $("input[name='FormSetting.ShowContractNumber']").val() == "Y" &&
        val.length == 0)
})

$.validator.unobtrusive.adapters.add("digiformrequiredcontractnumber", [], function (options) {
    options.rules["digiformrequiredcontractnumber"] = options.params;
    options.messages["digiformrequiredcontractnumber"] = options.message;
});

//conditional requirement of idnumber -----------------------------------------------
$.validator.addMethod("digiformrequiredidnumber", function (val, el) {
    return !(
        $("input:radio[name='IsClient']:checked").val() == "Y" &&
        $("input[name='FormSetting.ShowIDNumber']").val() == "Y" &&
        $("input[name='IsFO']:checked").val() == "N" &&
        val.length == 0)
})

$.validator.unobtrusive.adapters.add("digiformrequiredidnumber", [], function (options) {
    options.rules["digiformrequiredidnumber"] = options.params;
    options.messages["digiformrequiredidnumber"] = options.message;
});


//conditional requirement of city ------------------------------------------------------
$.validator.addMethod("digiformrequiredcity", function (val, el) {
    return !(
        $("input[name='FormSetting.ShowCity']").val() == "Y" &&
        val.length == 0)
})

$.validator.unobtrusive.adapters.add("digiformrequiredcity", [], function (options) {
    options.rules["digiformrequiredcity"] = options.params;
    options.messages["digiformrequiredcity"] = options.message;
});

//conditional requirement of district --------------------------------------------------
$.validator.addMethod("digiformrequireddistrict", function (val, el) {
    return !(
        $("input[name='FormSetting.ShowDistrict']").val() == "Y" &&
        val.length == 0)
})

$.validator.unobtrusive.adapters.add("digiformrequireddistrict", [], function (options) {
    options.rules["digiformrequireddistrict"] = options.params;
    options.messages["digiformrequireddistrict"] = options.message;
});



//validates that string is not made of same character --------------------------------
$.validator.addMethod("notsamecharacter", function (val, el, params) {
    var reg = new RegExp("^(.)\\1{1,}$", "i")

    return this.optional(el) ||
        !reg.test(val)
});

$.validator.unobtrusive.adapters.add("notsamecharacter", [], function (options) {
    options.rules["notsamecharacter"] = options.params;
    options.messages["notsamecharacter"] = options.message;
});

//check idnumber format -------------------------------------------------------------
$.validator.addMethod("idnumberformat", function (val, el) {
    var valid = false;
    val = ('0000000000' + val).substr(-8);
    if (/^\d{8}$/.test(val)) {
        var checkSum = 0;
        var checkNum = val.substr(-1);
        for (var i = 2; i <= 8; i++) {
            var n = val[val.length - i]
            checkSum += n * i;
        }
        num = 11 - checkSum % 11;
        if (num == 10)
            num = 0;
        else if (num == 11)
            num = 1
        valid = num == checkNum
        return $("input:radio[name='IsFO']:checked").val() == "Y" || valid
    }
})

$.validator.unobtrusive.adapters.add("idnumberformat", [], function (options) {
    options.rules["idnumberformat"] = options.params;
    options.messages["idnumberformat"] = options.message;
});

//check idnumber format -------------------------------------------------------------
$.validator.addMethod("contractnumberformat", function (val, el) {
    var reg = new RegExp("^(.)\\1{1,}$", "i")
    var valid = !reg.test(val);
    var validLength = val.length == 8 || val.length == 10

    return !(
        $("input:radio[name='IsClient']:checked").val() == "Y" &&
        $("input[name='FormSetting.ShowContractNumber']").val() == "Y" &&
        (!valid || !validLength)
    )
})

$.validator.unobtrusive.adapters.add("contractnumberformat", [], function (options) {
    options.rules["contractnumberformat"] = options.params;
    options.messages["contractnumberformat"] = options.message;
});

function manageContractNumberVisibility() {
    var showClientNumberBlock = $("input:radio[name='IsClient']:checked").val() == "Y";
    if (showClientNumberBlock)
        $('.contractNumberWrapper').slideDown(400)
    else {
        $('.contractNumberWrapper').slideUp(400)
        $("[name='IsFO'][value=Y]").click()
    }
}

// logic for showing / hiding parts of form -------------------------------------------
$(document).change(function (el) {
    $el = $(el.target)
    if ($el.attr('name') == 'IsClient') {
        //KOD NA SCHOVANIE CISLA ZMLUVY
        manageContractNumberVisibility();
    }

    if ($el.attr('name') == 'IsFO') {
     
        toggleFOCompanyFields();

        if ($('label.error[for="FirstName"]').length > 0)
            $("[name='FilledForm[firstName]']").valid()
        if ($('label.error[for="LastName"]').length > 0)
            $("[name='FilledForm[lastName]']").valid()
    }

    if ($el.is('select')) {
        $el.valid()
    }
})

function toggleFOCompanyFields() {
    var showBlock = $("input:radio[name='IsFO']:checked").val() == "Y";
    if (showBlock) {
        $('.date-of-birth-wrapper').show()
        $('.id-number-wrapper').hide()
        $('#FilledForm-IDNumber').val('')

        $('#firstname_label').show()
        $('#companytitle_label').hide()
        $('#lastname_label').show()
        $('#representative_label').hide()

    } else {
        $('.date-of-birth-wrapper').hide()
        $('.id-number-wrapper').show()
        $('#FilledForm-dateOfBirth').val('');

        $('#firstname_label').hide()
        $('#companytitle_label').show()
        $('#lastname_label').hide()
        $('#representative_label').show()
    }
}


// counters
$('[data-counter]').bind("input", function () {
    var id = $(this).attr('id')
    var val = $(this).val()
    var max = Number($(this).data('counter'))
    $('#' + id + '-counter').html(val.length + ' / ' + max)
})

//scroll to section in digiform
var hash = window.location.hash;
if (hash && hash.length > 0 && /#panelDigi.*/.test(hash)) {
    var id = hash
    $('a[href="#accordion-forms-body-' + id.split('-')[1] + '"]').click()
}