/*
* Lomtec jQuery startup
*/
$(document).ready(function () {
    if (typeof ContactPage !== 'undefined') ContactPage.initMap(); //to do - only for contact page

    // init jquery ajax loader
    initAjax();

    // custom form controls init
    initFormControls($(document));

    $('.collapse-2, .collapse-1').on('shown.bs.collapse', function (e) {
        var $card = $(this).closest('.card');
        //var $kpasHeaderHeight = $("#js-header").height();
        $('html,body').animate({
            scrollTop: $card.offset().top //- $kpasHeaderHeight
        }, 300);
        return false; //stop event from triggering event on level1 accordion item
    });


    //banners ----------------------------------

    document.querySelectorAll('[data-banner-click]').forEach(a => {
        //normal click
        a.addEventListener("click", function (e) {
            if (!$(e.currentTarget).data("banner-prevented")) {
                e.preventDefault();
                bannerImageClickRequest(this);
            }
        })
    })

});

function bannerImageClickRequest(item) {
    var $item = $(item);
    $item.attr('data-banner-prevented', true)

    var lng = $('html').attr('lang');
    var id = $item.attr('data-banner-click');
    $.post('/aw/categorybanner/click', { categorybannerID: id }, function (e) {
        item.click()
    })
}

function initFormControls($container) {

    initDatePicker($container);
    initAdvancedSearch($container);
    initPaging($container);

    // bootstrap tooltips
    $('[data-toggle="tooltip"]', $container).tooltip({
        placement: 'right',
        container: false
    });

    // init confirmations
    $('[data-confirm]', $container).each(function () {
        var $a = $(this);
        $a.confirmDialog();
    });
}

function initAjax() {
    var timeoutHandler;
    $(document).ajaxStart(function () {
        timeoutHandler = setTimeout(function () {
            $.LoadingOverlay("show", {
                image: "",
                fontawesome: "fa fa-spinner fa-spin"
            });
        }, 1500);
    });

    $(document).ajaxStop(function () {
        clearTimeout(timeoutHandler);
        $.LoadingOverlay("hide");
    });

    $(document).ajaxError(function (event, request) {
        $.LoadingOverlay("hide");
        // check for unauthorized code and redirect full page to login
        if (request.status === 401 || request.status === 403) window.location = "client/";
    });

    //$(document).ajaxComplete(function (event, request, settings) {
    //    console.log("Ajax Request Complete: " + settings.url + ", " + request.status);
    //});
}

function initAdvancedSearch($container) {
    $('.advanced', $container).click(function () {
        $('.advancedSearchBox', $container).slideToggle(200);
    });
}

function initDatePicker($container) {
    $('input[data-val-date]', $container).not('[readonly], [disabled]').each(function () {
        if ($(this).attr('data-val-minDateElement')) {

            var $minDate = $("#" + $(this).attr('data-val-minDateElement'));

            $(this).datepicker({
                onSelect: function (selected) {
                    $minDate.datepicker("option", "minDate", selected);
                },
                defaultDate: $(this).val(),
                maxDate: $minDate.val()
            });
        }
        if ($(this).attr('data-val-maxDateElement')) {

            var $maxDate = $("#" + $(this).attr('data-val-maxDateElement'));

            $(this).datepicker({
                onSelect: function (selected) {
                    $maxDate.datepicker("option", "maxDate", selected);
                },
                defaultDate: $(this).val(),
                minDate: $maxDate.val()
            });
        }
        else {
            $(this).datepicker({
                changeMonth: true,
                changeYear: true,
                yearRange: "-100:+20",
                onSelect: function (selected) {
                    $(this).focus().valid();
                }
            });
        }
    });
}

function initPaging($container) {
    $('.pager-pagesize', $container).on('change', function (e) {
        var self = $(this);
        var option = self.find("option[value=" + self.val() + "]:first");
        self.data('pager-pagesize', self.val());
        if (self.hasClass('ajax-processed')) return;
        window.location.href = option.attr('data-redirect');
    });
}

//ajax loader for lists
(function () {
    var a, b, c, d = 1,
        e = function () {
            $(".ajax-load").length > 0 && (f(), $(".ajax-load-more").on("click", function () {
                return $(this).addClass("hidden"), d = parseInt($(".ajax-load").attr("data-page")), d += 1, $(".ajax-load").attr("data-page", d), f(), !1
            }), $("a[data-ajax-filter]").on("click", function () {
                return $(".ajax-load-more").addClass("hidden"), $(".ajax-load").attr("data-page", 1), $("a.active[data-ajax-filter]").removeClass("active"), $(this).addClass("active"), f(), !1
            }))
        },
        f = function () {
            $(".ajax-load-more").addClass("hidden"), $(".ajax-preloader").removeClass("hidden"), a = "all", $(".active[data-ajax-filter]").length > 0 && (a = $(".active[data-ajax-filter]").attr("data-ajax-filter")), b = $(".ajax-load").attr("data-ajax-url"), c = $($(".ajax-load").attr("data-container")), d = parseInt($(".ajax-load").attr("data-page")), d <= 1 && c.empty(), $.ajax({
                dataType: "html",
                url: b,
                data: {
                    page: d,
                    filter: a
                },
                method: "post",
                success: function (a) {
                    c.append(a), $(window).trigger("resize"), $(".ajax-preloader").addClass("hidden"), c.find('*[data-lastitem="true"]').length <= 0 && $(".ajax-load-more").removeClass("hidden");
                },
                error: function (a) {
                    console.log("News load / ERROR")
                }
            })
        };
    e()
})();

//search autocomplete (with categories)

function showAllResults() {
    window.location.href = "/search?searchText=" + $('#search-input').val().replace(/\.\.\./gi, "");

}
(function () {
    $(function () {
        $("#search-input").autocomplete({
            delay: 200,
            width: "100%",
            maxHeight: 550,
            minLength: 3,
            source: 'search-predictive',
            select: function (event, ui) {
                //when autocomplete item is selected, redirect user to page 
                window.location.href = ui.item.URL;

                //or default functionality - on select, copy item's value to searchbox
                //$("#search-input").val(ui.item.Title);
                return false;
            },
            focus: function (event, ui) {
                //on focus, copy item's Title value to searchbox
                //$("#search-input").val(ui.item.Title);
                //return false;
            },
            open: function (event, ui) {
                $(this).autocomplete("widget").css({
                    "width": ($(this).outerWidth() + "px")
                });

                $lilast.appendTo("ul.ui-autocomplete");
            },
            create: function (event, ui) {
                $lilast = $("<li class='search-all g-px-30 g-py-15 g-bg-kpas-light-blue--hover ui-menu-item text-center'>").html($('#allres').html());
            }
        })
        .autocomplete("instance")._renderItem = function (ul, item) { //custom rendering of items
            var liContent = [
                //"<a href='" + item.URL + "'>",
                    "<span class='text h6 g-mb-2 text-uppercase g-font-weight-700 g-color-primary d-block'>" + item.Title + "</span>",
                    //(item.Category == '' ? "" : ("<span class='category d-block g-color-main'> (" + item.Category + ")</span>")),
                    "<span class='annotation g-color-main'>",
                        item.Annotation,
                    "</span>",
                //"</a>"
            ].join("");
            return $("<li class='border-bottom border-l-blue-color g-px-30 g-py-10 g-bg-kpas-light-blue--hover'>")
              .append(liContent)
              .appendTo(ul)
              .data("item.autocomplete", item);
        };
    });
})();


//accordion anchor
(function () {
    var hash = window.location.hash;
    if (hash) {
        var elToScroll = $(hash)
        var closestAcc = elToScroll.closest('.u-accordion')
        
        if (closestAcc.length > 0) {
            //level1 - just klick
            if (closestAcc.attr('data-level') == 1) {
                elToScroll.parents('.card').first().find('.collapse').first().collapse('show');
            }
            //level2 - first click outer, than inner item
            if (closestAcc.attr('data-level') == 2) {
                var firstLevelAcc = closestAcc.parents('.card').first()
                firstLevelAcc.find('.collapse').first().collapse('show');
                elToScroll.parents('.card').first().find('.collapse').collapse('show');

            }

        }
    }
})()



//fund graphs - ajax loading
$('#accordion .fund-accordion').on('show.bs.collapse', function (e) {
    var $t = $(this);
    var type = $t.attr("data-type");
    var $graphContent = $t.find(".graph");

    if ($t.hasClass("show-graph")) {
        if (!$t.hasClass("graph-shown")) {
            //spinner while loading graph
            $graphContent.append('<div class="spinner"> &nbsp; <div class="fancybox-loading"></div> </div>');

            $.get("/vyvoj-fondov/graf/" + $t.data("type") + ($t.data("subtitle") ? "?subtitle=" + $t.data("subtitle") : ""), function (response) {
                $graphContent.find('.spinner').remove();
                $graphContent.show().append(response);
                $t.addClass("graph-shown");

                $('html, body').animate({
                    scrollTop: $t.offset().top
                }, 500);
            })
        }
    }
})

//fund accordion filter date click
$('#accordion .fund-accordion').click(function (e) {
    var $t = $(e.target);
    var $thisBlock = $(this);
    var dateFrom = $thisBlock.find(".datefrom");
    var dateTo = $thisBlock.find(".dateto");


    if ($t.hasClass("datesection")) {
        var type = $thisBlock.data("type");
        var subtitle = $thisBlock.data("subtitle");

        var url = "/vyvoj-fondov/graf/" + type + "?" + (subtitle ? "&subtitle=" + subtitle : "") + "&datefrom=" + $.datepicker.formatDate("dd.mm.yy", dateFrom.datepicker('getDate')) + "&dateto=" + $.datepicker.formatDate("dd.mm.yy", dateTo.datepicker('getDate'));
        $.get(url, function (response) {

            $thisBlock.find("div.graph").html(response)
        })
    }
})


$(document).ready(function () {
    var koopHash = window.location.href.split('#')[1];
    //console.log(koopHash)
    if (koopHash !== undefined) {

        var elemK = $('#' + koopHash + '');
        var fixedHeaderAdjust = 90;

        $('html, body').animate({
            scrollTop: elemK.offset().top - fixedHeaderAdjust
        }, 800, function () {
            // Add hash (#) to URL when done scrolling (default click behavior)
            //window.location.hash = hash;
        });

        elemK.click();
    }
});

//constructs ek link
function constructEKLink(template, code) {
    return atob(template).replace("{0}", encodeURIComponent(code));
}